import type { Observable, Subject } from 'rxjs'
import {
  animationFrameScheduler,
  BehaviorSubject,
  fromEvent as ObservableFromEvent,
  merge,
  scheduled,
  Subscription,
  tap,
} from 'rxjs'
import {
  combineLatestWith,
  filter,
  map,
  repeat,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators'
import { isInProximity, isInRange } from './utilities'
import type { FpCaptchaConfig } from './config'

interface PositionEvent {
  x: number
  y: number
  timeStamp: number
}

interface ISetupChartInteractionReturnType {
  puzzlePosition$: Subject<PositionEvent>
  judgement$: Observable<[ number, PositionEvent ]>
  subscription: Subscription
  resetPuzzlePosition: ()=> void
}

export function setupChartInteraction(
  canvas: HTMLCanvasElement,
  config: FpCaptchaConfig,
): ISetupChartInteractionReturnType {
  const dragThreshold = config.getOne('dragThreshold')

  const {
    dragStart$,
    dragMove$,
    dragEnd$,
    dragCancel$,
  } = createInteractionListener(canvas)

  const puzzlePosition$ = new BehaviorSubject<PositionEvent>({
    x: 30,
    y: 30,
    timeStamp: 0,
  })
  const subscription = new Subscription()

  const validDrag$ = dragStart$.pipe(
    withLatestFrom(puzzlePosition$), // => [a, b]
    filter(([ dragStartPosition, puzzlePosition ]) =>
      isInProximity([ dragStartPosition.x, puzzlePosition.x ], dragThreshold) &&
      isInProximity([ dragStartPosition.y, puzzlePosition.y ], dragThreshold),
    ),
  )

  const dragSubscription = validDrag$
    .pipe(
      switchMap(([ dragStart, puzzlePosition ]) =>
        dragMove$.pipe(
          takeUntil(merge(dragEnd$, dragCancel$)),
          filter(dragMovePosition =>
            isInRange(dragMovePosition.x, [ 30, 270 ]) &&
            isInRange(dragMovePosition.y, [ 30, 120 ]),
          ),
        ),
      ),
    )
    .subscribe(position => puzzlePosition$.next(position))

  subscription.add(dragSubscription)

  const time$ = dragStart$
    .pipe(
      combineLatestWith(dragEnd$),
      map(([ start, end ]) => end.timeStamp - start.timeStamp),
    )

  const judgement$ = validDrag$
    .pipe(
      switchMap(() => dragEnd$),
      combineLatestWith(time$, puzzlePosition$),
      map(([ , time, puzzlePosition ]) => [ time, puzzlePosition ] as [ number, PositionEvent ]),
      take(1),
      repeat(),
    )

  return {
    puzzlePosition$,
    judgement$,
    subscription,
    resetPuzzlePosition: () => puzzlePosition$.next({
      x: 30,
      y: 30,
      timeStamp: Date.now(),
    }),
  } as const
}

function createInteractionListener(canvas: HTMLCanvasElement) {
  const mouseDown$ = ObservableFromEvent<MouseEvent>(canvas, 'mousedown')
    .pipe(transformMouseEventToPosition())

  const mouseMove$ = ObservableFromEvent<MouseEvent>(canvas, 'mousemove')
    .pipe(
      preventDefault(),
      transformMouseEventToPosition(),
    )
  const mouseOut$ = ObservableFromEvent<UIEvent>(canvas, 'mouseout')
  const mouseUp$ = ObservableFromEvent<UIEvent>(canvas, 'mouseup')

  const canvasPosition$ = scheduled(ObservableFromEvent(document, 'resize'), animationFrameScheduler)
    .pipe(
      startWith({
        x: 0,
        y: 0,
      }),
      map<UIEvent, PositionEvent>(() => {
        const canvasRect = canvas.getBoundingClientRect()

        return {
          x: canvasRect.left,
          y: canvasRect.top,
          timeStamp: Date.now(),
        }
      }),
    )

  const touchStart$ = ObservableFromEvent<TouchEvent>(canvas, 'touchstart')
    .pipe(transformTouchEventToPosition(canvasPosition$))

  const touchMove$ = ObservableFromEvent<TouchEvent>(canvas, 'touchmove')
    .pipe(
      preventDefault(),
      transformTouchEventToPosition(canvasPosition$),
    )
  const touchEnd$ = ObservableFromEvent<UIEvent>(canvas, 'touchend')
  const touchCancel$ = ObservableFromEvent<UIEvent>(canvas, 'touchcancel')

  return {
    dragStart$: merge(mouseDown$, touchStart$),
    dragMove$: merge(mouseMove$, touchMove$),
    dragEnd$: merge(mouseUp$, touchEnd$),
    dragCancel$: merge(mouseOut$, touchCancel$),
  } as const
}

const transformMouseEventToPosition = () => (stream$: Observable<MouseEvent>) =>
  stream$.pipe(
    map<MouseEvent, PositionEvent>(event => ({
      x: event.offsetX,
      y: event.offsetY,
      timeStamp: event.timeStamp,
    })),
  )

const transformTouchEventToPosition = (canvasPosition$: Observable<PositionEvent>) =>
  (stream$: Observable<TouchEvent>) =>
    stream$.pipe(
      combineLatestWith(canvasPosition$),
      map<[ TouchEvent, PositionEvent ], PositionEvent>(([ event, canvasPosition ]) => ({
        x: event.targetTouches[0].clientX - canvasPosition.x,
        y: event.targetTouches[0].clientY - canvasPosition.y,
        timeStamp: event.timeStamp,
      })),
    )

const preventDefault = () => (stream$: Observable<Event>) => stream$.pipe(tap(event => event.preventDefault()))

import type { FpCaptchaConfig } from './config'
import { chunkArray, findIndexIn2DArray, loadImage } from './utilities'

export async function decipherChart(
  canvasContext: CanvasRenderingContext2D,
  chartUrl: string,
  shuffleMatrix: number[],
  config: FpCaptchaConfig,
): Promise<ImageData> {
  const chunkSize = config.getOne('chunkSize')
  const chartImage = await loadImage(chartUrl)

  canvasContext.clearRect(0, 0, chartImage.width, chartImage.height)

  const chunks = chunkArray(
    Array(shuffleMatrix.length)
      .fill(null)
      .map((_, index) => index),
    chunkSize,
  )
  const [ chunkWidth, chunkHeight ] = [ chartImage.width / chunks[0].length, chartImage.height / chunks.length ]

  for (const index of shuffleMatrix) {
    const mark = shuffleMatrix[index]
    const [ sourceX, sourceY ] = getChunkDimensionByIndex(chunks, chunkWidth, chunkHeight, index)
    const [ destinX, destinY ] = getChunkDimensionByIndex(chunks, chunkWidth, chunkHeight, mark)

    canvasContext.drawImage(chartImage,
      sourceX, sourceY, chunkWidth, chunkHeight,
      destinX, destinY, chunkWidth, chunkHeight,
    )
  }

  return canvasContext.getImageData(0, 0, chartImage.width, chartImage.height)
}

function getChunkDimensionByIndex(
  chunks: number[][],
  chunkWidth: number,
  chunkHeight: number,
  index: number,
): [ number, number ] {
  const [ row, col ] = findIndexIn2DArray(chunks, el => el === index)

  return [
    col * chunkWidth,
    row * chunkHeight,
  ]
}

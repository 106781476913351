export function createTemplate(): HTMLDivElement {
  const container = document.createElement('div')
  container.className = 'fp-captcha-container'

  const modal = `
    <div class="fp-captcha-frame">
      <div class="fp-captcha-header">
        <div class="fp-captcha-instruction"></div>
        <button id="fp-captcha-close" class="fp-captcha-button">
          <div class="fp-captcha-icon-times"/>
        </button>
      </div>
  
      <div class="fp-captcha-canvas-container">
        <div class="fp-captcha-overlay">
          <div class="fp-captcha-spinner fp-captcha-icon-spinner"></div>
          <div class="fp-captcha-info">
            <button id="fp-captcha-info-close" class="fp-captcha-button">
              <div class="fp-captcha-icon-light-times"></div>
            </button>
            <p class="fp-captcha-info-text"></p>
          </div>
          <div class="fp-captcha-result"></div>
          
          <div class="fp-captcha-error">
            <div class="fp-captcha-icon-refresh"></div>
            <p class="fp-captcha-error-text"></p>
          </div>
          
          <div class="fp-captcha-error-network">
            <div class="fp-captcha-icon-network-error"></div>
            <p class="fp-captcha-error-network-text"></p>
          </div>
        </div>
      
        <canvas id="fp-captcha-canvas">
          Your browser does not support canvas
        </canvas>
      </div>
  
      <div class="fp-captcha-footer">
          <button id="fp-captcha-refresh" class="fp-captcha-button">
            <div class="fp-captcha-icon-refresh"/>
          </button>
          <button id="fp-captcha-info" class="fp-captcha-button">
            <div class="fp-captcha-icon-info"/>
          </button>
        <p class="fp-captcha-powered-by">
          Powered by Funpodium
        </p>
      </div>
    </div>
  `

  container.insertAdjacentHTML('beforeend', modal)

  return container
}

/* eslint-disable accessor-pairs */
import { filter, fromEvent, interval, Subject, Subscription } from 'rxjs'
import { take } from 'rxjs/operators'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FpCaptcha } from './FpCaptcha'
import { createTemplate } from './template'
import type { FpCaptchaConfigType } from './config'
import type { FpCaptchaLocaleDictionary } from './locale'
import { FpCaptchaErrorCode, FpCaptchaViewEventType } from './types'
import type { FpCaptchaJudgement } from './judgement'
import './styles.css'

export class FpCaptchaView {
  private readonly _container: HTMLElement
  private _fpCaptcha: FpCaptcha
  private _loading = false
  private _showView = false
  private _showResult = false
  private _showInfo = false
  private _showError = false
  private _showNetworkError = false
  private _subscriptions = new Subscription()
  private _emitter$ = new Subject<[ FpCaptchaViewEventType, unknown ]>()

  constructor(config?: Partial<FpCaptchaConfigType>) {
    this._container = createTemplate()

    this._fpCaptcha = new FpCaptcha(
      this._container.querySelector('#fp-captcha-canvas'),
      { config },
    )
  }

  public addEventListener(
    eventType: FpCaptchaViewEventType,
    callback: (args: unknown)=> void,
  ): Subscription {
    const subscription = this._emitter$
      .pipe(
        filter(([ type ]) => eventType === type),
      )
      .subscribe(([ , args ]) => callback(args))

    this._subscriptions.add(subscription)

    return subscription
  }

  public mount(): void {
    this.showView = false
    document.body.appendChild(this._container)

    const refreshSub = fromEvent(
      this._container.querySelector('#fp-captcha-refresh'),
      'click',
    )
      .subscribe(() => {
        this.refresh()
          .catch(console.error)
      })

    const closeSub = fromEvent(
      this._container.querySelector('#fp-captcha-close'),
      'click',
    )
      .subscribe(() => {
        this.unmount()
      })

    const infoSub = fromEvent(
      this._container.querySelector('#fp-captcha-info'),
      'click',
    )
      .subscribe(() => {
        this.showInfo = !this._showInfo
      })

    const infoCloseSub = fromEvent(
      this._container.querySelector('#fp-captcha-info-close'),
      'click',
    )
      .subscribe(() => {
        this.showInfo = false
      })

    this._subscriptions.add(refreshSub)
    this._subscriptions.add(closeSub)
    this._subscriptions.add(infoSub)
    this._subscriptions.add(infoCloseSub)
  }

  public unmount(): void {
    this.showView = false
    this.showResult = false
    this.showInfo = false
    this.showNetworkError = false
    this.showError(false, '')

    this._fpCaptcha.teardown()
    this._subscriptions.unsubscribe()
    this._subscriptions = new Subscription()
  }

  public async refresh(): Promise<void> {
    this.showInfo = false
    this.showResult = false
    this.showNetworkError = false
    this.showError(false, '')
    this.loading = true

    try {
      await this._fpCaptcha.refresh()

      this.loading = false
    } catch (e) {
      this.loading = false
      this.showNetworkError = true

      throw e
    }
  }

  public async build(challengeUuid: string): Promise<void> {
    this.loading = true
    this.showView = true

    this._fpCaptcha.addResultListener(judgement => {
      this.showResult = false

      if ([
        FpCaptchaErrorCode.REFRESH_EXCEEDED,
        FpCaptchaErrorCode.TIMEOUT,
      ].includes(judgement.code)) {
        setImmediate(() => {
          this.showError(true, judgement.message)
        })
      } else if (judgement.code === FpCaptchaErrorCode.JUDGEMENT_EXCEEDED) {
        setImmediate(() => {
          this.refresh()
          this._setResult(judgement)
        })
      } else {
        setImmediate(() => {
          this._setResult(judgement)
        })

        const subscription = interval(this._fpCaptcha.config.dismissTime)
          .pipe(take(1))
          .subscribe(() => {
            this.showResult = false

            if (judgement.isPassed) {
              this.unmount()
            }
          })

        this._subscriptions.add(subscription)
      }

      this._emitter$.next([
        judgement.isPassed ? FpCaptchaViewEventType.PASSED : FpCaptchaViewEventType.FAILED,
        judgement,
      ])
    })

    try {
      await this._fpCaptcha.build(challengeUuid)

      this.loading = false
      this._updateUI(this._fpCaptcha.locale)
    } catch (e) {
      this.loading = false
      this.showNetworkError = true

      throw e
    }
  }

  public set loading(_loading: boolean) {
    this._showOverlay(_loading)

    if (_loading) {
      this._container.querySelector('.fp-captcha-spinner')
        .classList
        .add('show')
    } else {
      this._container.querySelector('.fp-captcha-spinner')
        .classList
        .remove('show')
    }

    this._loading = _loading
  }

  private _setResult(result: FpCaptchaJudgement): void {
    const element = this._container.querySelector('.fp-captcha-result')
    element.innerHTML = result.isPassed ? this._fpCaptcha.locale.success : result.message
    element.classList.add(result.isPassed ? 'success' : 'error')

    this.showResult = true
  }

  private _showOverlay(_show: boolean): void {
    if (_show) {
      this._container.querySelector('.fp-captcha-overlay')
        .classList
        .add('show')
    } else {
      this._container.querySelector('.fp-captcha-overlay')
        .classList
        .remove('show')
    }
  }

  private set showView(_show: boolean) {
    if (_show) {
      this._container
        .classList
        .add('show')
      disableBodyScroll(this._container)
      this._emitter$.next([ FpCaptchaViewEventType.OPEN, undefined ])
    } else {
      this._container
        .classList
        .remove('show')
      enableBodyScroll(this._container)
      this._emitter$.next([ FpCaptchaViewEventType.CLOSE, undefined ])
    }

    this._showView = _show
  }

  private set showResult(_show: boolean) {
    this.showInfo = false
    this._showOverlay(_show)
    const element = this._container
      .querySelector('.fp-captcha-result')

    if (_show) {
      element
        .classList
        .add('show')
    } else {
      element
        .classList
        .remove('show', 'success', 'error')
    }

    this._showResult = _show
  }

  private set showInfo(_show: boolean) {
    if (this._showError || this._showResult || this._showNetworkError) return

    this._showOverlay(_show)
    const element = this._container
      .querySelector('.fp-captcha-info')
    element.querySelector('.fp-captcha-info-text').innerHTML = this._fpCaptcha.locale.description

    if (_show) {
      element
        .classList
        .add('show')
    } else {
      element
        .classList
        .remove('show')
    }

    this._showInfo = _show
  }

  private showError(_show: boolean, message: string) {
    this.showInfo = false
    this._showOverlay(_show)
    const element = this._container.querySelector('.fp-captcha-error')
    element.querySelector('.fp-captcha-error-text').innerHTML = message

    if (_show) {
      element
        .classList
        .add('show')
    } else {
      element
        .classList
        .remove('show')
    }

    this._showError = _show
  }

  private set showNetworkError(_show: boolean) {
    this.showInfo = false
    this._showOverlay(_show)
    const element = this._container.querySelector('.fp-captcha-error-network')

    if (_show) {
      element
        .classList
        .add('show')
    } else {
      element
        .classList
        .remove('show')
    }

    this._showNetworkError = _show
  }

  private _updateUI(locale: Partial<FpCaptchaLocaleDictionary>): void {
    const instruction = this._container.querySelector('.fp-captcha-instruction')
    const poweredBy = this._container.querySelector('.fp-captcha-powered-by')
    const networkError = this._container.querySelector('.fp-captcha-error-network-text')

    instruction.innerHTML = locale.info
    poweredBy.innerHTML = locale.provide
    networkError.innerHTML = locale.abnormal
  }
}

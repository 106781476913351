export enum FpCaptchaMode {
  STRICT = 'STRICT',
  BYPASS = 'BYPASS',
}

export enum FpCaptchaErrorCode {
  SUCCESS = 10001,
  TIMEOUT = 63002,
  ILLEGAL_STEP = 63003,
  UNKNOWN_DEVICE = 63006,
  NETWORK_ISSUE = 63007,
  REFRESH_EXCEEDED = 63301,
  WRONG_ANSWER = 63401,
  COST_OUT_OF_RANGE = 63402,
  JUDGEMENT_EXCEEDED = 63403,
}

export enum FpCaptchaViewEventType {
  OPEN = 'open',
  PASSED = 'passed',
  FAILED = 'failed',
  CLOSE = 'close'
}

export interface FpCaptchaConfigType {
  apiUrl: string
  applicationLocale: string
  dismissTime: number
  chunkSize: number
  dragThreshold: number
}

export class FpCaptchaConfig {
  private _config: FpCaptchaConfigType

  constructor() {
    this.clear()
  }

  get config(): FpCaptchaConfigType {
    return Object.freeze(this._config)
  }

  set config(config: FpCaptchaConfigType) {
    this._config = config
  }

  getOne<K extends keyof FpCaptchaConfigType>(key: K): FpCaptchaConfigType[K] {
    return this._config[key]
  }

  // eslint-disable-next-line max-len
  setOne<K extends keyof FpCaptchaConfigType, V extends FpCaptchaConfigType[K]>(key: K, value: V = undefined): FpCaptchaConfigType {
    if (key in this._config) {
      this._config = {
        ...this._config,
        [key]: value,
      }
    }

    return this.config
  }

  set(config: Partial<FpCaptchaConfigType>): FpCaptchaConfigType {
    this._config = {
      ...this._config,
      ...config,
    }

    return this.config
  }

  clear(): void {
    this._config = {
      apiUrl: '',
      applicationLocale: 'en',
      dismissTime: 1000,
      chunkSize: 10,
      dragThreshold: 20,
    }
  }
}

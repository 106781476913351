import axios from 'axios'
import type { FpCaptchaConfig } from './config'
import type { FpCaptchaUserAgentAnalysis } from './analyzeUserAgent'
import { FpCaptchaMode } from './types'

export interface FpCaptchaJudgementInput {
  id: string
  answers: {
    x: number
    y: number
  }
  cost: number
}

export interface FpCaptchaJudgementResponse {
  code: number
  message: string
  status: string
}

export interface FpCaptchaJudgement {
  challengeUuid: string
  code: number
  message: string
  isPassed: boolean
}

export async function makeJudgement(
  {
    id,
    answers,
    cost,
  }: FpCaptchaJudgementInput,
  config: FpCaptchaConfig,
  device?: Partial<FpCaptchaUserAgentAnalysis>,
): Promise<FpCaptchaJudgement> {
  let result: FpCaptchaJudgement = {
    challengeUuid: id,
    code: 0,
    message: '',
    isPassed: false,
  }

  try {
    const response = await axios.post<FpCaptchaJudgementResponse>(
      '/api/v1.0/judgement',
      {
        challengeUuid: id,
        applicationLanguage: config.getOne('applicationLocale'),
        activity: {
          answers,
          cost,
        },
        device,
      },
      {
        responseType: 'json',
        baseURL: config.getOne('apiUrl'),
      },
    )

    const data = response.data

    result = {
      challengeUuid: id,
      code: data.code,
      message: data.message,
      isPassed: true,
    }
  } catch (error) {
    console.error(error)

    if (error.response?.data) {
      const data = error.response.data

      result = {
        challengeUuid: id,
        code: data.code,
        message: data.message,
        isPassed: data.mode === FpCaptchaMode.BYPASS,
      }
    }
  }

  return result
}

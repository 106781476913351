import { animationFrameScheduler, from, scheduled, Subject, Subscription, switchMap } from 'rxjs'
import { filter } from 'rxjs/operators'
import type { FpCaptchaConfigType } from './config'
import { FpCaptchaConfig } from './config'
import type { FpCaptchaChallenge } from './challenge'
import { retrieveChallengeInfo } from './challenge'
import { loadImage } from './utilities'
import { decipherChart } from './chart-decipher'
import { setupChartInteraction } from './chart-interact'
import type { FpCaptchaJudgement } from './judgement'
import { makeJudgement } from './judgement'
import type { FpCaptchaUserAgentAnalysis } from './analyzeUserAgent'
import { analyzeUserAgent } from './analyzeUserAgent'
import type { FpCaptchaLocale, FpCaptchaLocaleDictionary } from './locale'
import type { FpCaptchaErrorCode } from './types'

export interface FpCaptchaOptions {
  window: Window
  config: Partial<FpCaptchaConfigType>
}

export class FpCaptcha {
  private readonly _canvasContext: CanvasRenderingContext2D
  private readonly _device: Partial<FpCaptchaUserAgentAnalysis>
  private readonly _canvas: HTMLCanvasElement

  private _emitter = new Subject<FpCaptchaJudgement>()
  private _subscription: Subscription = new Subscription()
  private _resultSubscription: Subscription = new Subscription()
  private _config: FpCaptchaConfig = new FpCaptchaConfig()

  private _judgement: FpCaptchaJudgement
  private _locale: FpCaptchaLocale
  private _challengeUuid: string

  constructor(
    canvas?: HTMLCanvasElement,
    options?: Partial<FpCaptchaOptions>,
  ) {
    if (canvas) {
      if (!canvas.getContext('2d')) {
        throw new Error('canvas unsupported')
      }

      canvas.width = 300
      canvas.height = 150

      this._canvasContext = canvas.getContext('2d')
    }

    this._canvas = canvas
    this._device = analyzeUserAgent(options?.window)

    this._handleOptions(options)
  }

  public refresh(): Promise<void> {
    this.teardown(true)

    return this.build(this._challengeUuid, true)
  }

  public async build(challengeUuid: string, isRefresh = false): Promise<void> {
    this._challengeUuid = challengeUuid
    let challenge: FpCaptchaChallenge

    try {
      challenge = await retrieveChallengeInfo(challengeUuid, this._config, this._device, isRefresh)
    } catch (err) {
      if (err.response?.data) {
        const data = err.response.data
        this._fail(data.code, data.message)
      }

      return
    }

    if (challenge.currentLocale) {
      this._locale = challenge.currentLocale
    }

    if (challenge.mode === 'BYPASS') {
      this._bypass('bypassed at challenge retrieval')

      return
    }

    const keyImage = await loadImage(challenge.keyUri)

    const chartImage = await decipherChart(
      this._canvasContext,
      challenge.chartUri,
      challenge.shuffleMatrix,
      this._config,
    )

    const {
      puzzlePosition$,
      judgement$,
      subscription,
      resetPuzzlePosition,
    } = setupChartInteraction(this._canvas, this._config)

    const drawSub = scheduled(puzzlePosition$, animationFrameScheduler)
      .subscribe(puzzlePosition => {
        this._canvasContext.clearRect(0, 0, 300, 150)

        this._canvasContext.putImageData(chartImage, 0, 0)

        this._canvasContext.drawImage(
          keyImage,
          puzzlePosition.x - (keyImage.width / 2),
          puzzlePosition.y - (keyImage.height / 2),
        )
      })

    const judgeSub = judgement$
      .pipe(
        filter(() => !this.judgement?.isPassed),
        switchMap(([ operateTime, puzzlePosition ]) =>
          from(makeJudgement({
            id: this._challengeUuid,
            answers: {
              x: puzzlePosition.x - (keyImage.width / 2),
              y: puzzlePosition.y - (keyImage.height / 2),
            },
            cost: operateTime,
          }, this._config, this._device)),
        ),
      )
      .subscribe(result => {
        this._judgement = result
        this._emitter.next(result)

        if (!result.isPassed) {
          resetPuzzlePosition()
        }
      })

    this._subscription.add(drawSub)
    this._subscription.add(judgeSub)
    this._subscription.add(subscription)
  }

  public teardown(isRefresh?: boolean): void {
    this._judgement = undefined
    this._canvasContext.clearRect(0, 0, 300, 150)
    this._subscription.unsubscribe()
    this._subscription = new Subscription()

    if (!isRefresh) {
      this._challengeUuid = undefined
      this._resultSubscription.unsubscribe()
      this._resultSubscription = new Subscription()
      this._emitter.complete()
      this._emitter = new Subject()
    }
  }

  public addResultListener(callback: (judgement: FpCaptchaJudgement)=> void): Subscription {
    const sub = this._emitter.subscribe(callback)
    this._resultSubscription.add(sub)

    return sub
  }

  public get judgement(): FpCaptchaJudgement {
    return this._judgement
  }

  public get config(): FpCaptchaConfigType {
    return this._config.config
  }

  public get locale(): Readonly<Partial<FpCaptchaLocaleDictionary>> {
    return this._locale.getAll()
  }

  private _bypass(message = ''): void {
    this._emitter.next({
      challengeUuid: this._challengeUuid,
      code: 0,
      message,
      isPassed: true,
    })
  }

  private _fail(code: FpCaptchaErrorCode, message = ''): void {
    this._emitter.next({
      challengeUuid: this._challengeUuid,
      code,
      message,
      isPassed: false,
    })
  }

  private _handleOptions(options: Partial<FpCaptchaOptions>): void {
    if (options?.config) {
      this._config.set(options.config)
    }
  }
}

export interface FpCaptchaUserAgentAnalysis {
  domain: string
  ip: string
  language: string
  udid: string
  userAgent: string
}

export function analyzeUserAgent(_window?: Window): Partial<FpCaptchaUserAgentAnalysis> {
  if (_window === null || _window === undefined) return {}

  const { origin } = _window.location

  const {
    userAgent,
    language,
  } = _window.navigator

  return {
    domain: origin,
    language: language.split('-')[0],
    userAgent,
  }
}

export function chunkArray<T>(array: T[], size = 1): T[][] {
  const results = []

  while (array.length) {
    results.push(array.splice(0, size))
  }

  return results
}

export function findIndexIn2DArray<T>(array: T[][], predicate: (element: T)=> boolean): [ number, number ] {
  let colIndex = -1
  const rowIndex = array.findIndex(row => {
    colIndex = row.findIndex(predicate)

    return colIndex !== -1
  })

  return [ rowIndex, colIndex ]
}

export function isInProximity(numbers: [ number, number ], threshold: number): boolean {
  const delta = Math.abs(numbers[0] - numbers[1])

  return delta <= threshold
}

export function isInRange(testee: number, range: [ number, number ]): boolean {
  return testee >= range[0] && testee < range[1]
}

export function loadImage(imageUrl: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()

    image.addEventListener('load', () => {
      resolve(image)
    })

    image.addEventListener('error', event => {
      reject(event)
    })

    image.crossOrigin = 'Anonymous'
    image.src = imageUrl
  })
}

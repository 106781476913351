import axios from 'axios'
import type { FpCaptchaConfig } from './config'
import type { FpCaptchaUserAgentAnalysis } from './analyzeUserAgent'
import { FpCaptchaLocale } from './locale'
import { FpCaptchaMode } from './types'

export async function retrieveChallengeInfo(
  challengeUuid: string,
  config: FpCaptchaConfig,
  extraInfo?: Partial<FpCaptchaUserAgentAnalysis>,
  isRefresh = false,
): Promise<FpCaptchaChallenge> {
  if (!challengeUuid) {
    return null
  }

  const endpointUri = `/api/v1.0/${isRefresh ? 'chart' : 'challenge'}/${challengeUuid}`

  const response = await axios.post(
    endpointUri,
    {
      applicationLanguage: config.getOne('applicationLocale'),
      device: extraInfo,
    },
    {
      responseType: 'json',
      baseURL: config.getOne('apiUrl'),
    },
  )

  return {
    ...response.data,
    mode: FpCaptchaMode[response.data.mode],
    currentLocale: response.data.currentLocale ? new FpCaptchaLocale(response.data.currentLocale) : null,
  }
}

export interface FpCaptchaChallenge {
  challengeUuid: string
  chartUri: string
  keyUri: string
  mode: FpCaptchaMode
  shuffleMatrix: number[]
  answers?: {
    x: number
    y: number
  }
  currentLocale: FpCaptchaLocale

  refreshCount?: number
  retryCount?: number
}

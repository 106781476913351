export interface FpCaptchaLocaleDictionary {
  fail: string
  abnormal: string
  provide: string
  success: string
  refresh: string
  description: string
  info: string
}

export class FpCaptchaLocale {
  private _retrievedLocale: Partial<FpCaptchaLocaleDictionary>
  private static readonly _defaultLocale: Partial<FpCaptchaLocaleDictionary> = Object.freeze({
    fail: 'Validation failed. Please try again.',
    abnormal: 'The network is abnormal, please try again.',
    provide: 'This captcha service is hosted by Funpodium Inc.',
    success: 'Validation succeeded.',
    refresh: 'refresh image',
    description: 'Kindly press the puzzle continuously and drag the puzzle into accurate spot to complete mapping. ' +
      'Sent out your answer and than verification result will return afterward.',
    info: 'Kindly drag the puzzle rightward and complete mapping the puzzle.',
  })

  constructor(retrievedLocale: Partial<FpCaptchaLocaleDictionary> = {}) {
    this._retrievedLocale = Object.freeze(retrievedLocale)
  }

  public get(key: keyof FpCaptchaLocaleDictionary | string): string {
    return this._retrievedLocale[key] ?? FpCaptchaLocale._defaultLocale[key]
  }

  public getAll(): Readonly<Partial<FpCaptchaLocaleDictionary>> {
    return Object.freeze({
      ...FpCaptchaLocale._defaultLocale,
      ...this._retrievedLocale,
    })
  }

  public replace(retrievedLocale: Partial<FpCaptchaLocaleDictionary>): void {
    this._retrievedLocale = Object.freeze(retrievedLocale)
  }
}
